import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';
import { Container, Row, Col } from "react-bootstrap";
import loadable from "@loadable/component";
import Layout from "../components/layout";
import { collectBreadcrum } from "../utils";

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const PeopleDetailIntro = loadable(() => import("../components/PeopleDetailIntro/PeopleDetailIntro"));
const PeopleDetailDesc = loadable(() => import("../components/PeopleDetailDesc/PeopleDetailDesc"));
const PeopleDetailSidebar = loadable(() => import("../components/PeopleDetailSidebar/PeopleDetailSidebar"));

const PeopleDetail = (props) => {

    const teamData = props?.data?.wpPost;

    // Sticky scroll
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 80)
        })
    },[])
    // Sticky scroll

    return (
        <Layout>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule data={collectBreadcrum(props?.pageContext?.data)}/>

            <div className="people-detail-wrapper">
                <Container>
                    <Row>
                        <Col xl={8} className="order-xl-1 order-2">
                            <PeopleDetailIntro data={teamData} />

                            <PeopleDetailDesc data={teamData} />
                        </Col>
                        <Col xl={1} className="order-xl-2"></Col>
                        <Col xl={3} className="order-xl-3 order-1">
                            <div className={`people-sidebar position-sticky ${scroll ? "scrolled" : ""}`}>
                                <PeopleDetailSidebar data={teamData} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ($page_id: String) {
      wpPost(id: {eq: $page_id}) {
        uri
        title
        status
        slug
        categories {
          nodes {
            name
          }
        }
        content
        featuredImage {
          node {
            sourceUrl
          }
        }
        ACF_team {
          email
          phone
          position
        }
      }
    }
`

export default PeopleDetail